///
/// @file global/_variables.scss
///
/// \brief Variables
///
/// These are universally needed files that are SCSS-specific. Most of the
/// following do not directly result in rendered CSS.
/// @setup change all for new brand

// Layout
$container-max-width: 1920px;
$max-width-num: 1300;
$landscape-up: 1024px;
$max-width: #{$max-width-num}px;
$header-height: 60px !default; // used to offset anchors, etc.
$xxxlarge-max-width-num: 1920;
$xxxlarge-max-width: #{$xxxlarge-max-width-num}px;
$tablet: 768px;
$desktop: 1024px;
$mobileMedium: 375px;

// Specific site container width
$xxxlarge-site-width: false !default;

// Typography
$main-font: 'Gotham Book', arial !default;
$main-font-regular: $main-font !default;
$base-font-family: $main-font !default;
$header-font-family: $main-font !default;
$medium-font-family: 'Gotham Medium', arial !default;
$bold-font-family: 'Gotham Bold', arial !default;
$main-font-medium: $medium-font-family;
$light-font-family: 'Gotham Light', arial !default;
$main-font-light: $light-font-family;
$body-font: 'Gotham Book', arial !default;
$body-font-bold: 'Gotham Book', arial !default;

//ltr
$rdirection: right !default;
$ldirection: left !default;
$text-direction: ltr !default;

//Sizes
$base-font-size: 14px !default;
$base-line-height: 1.45em !default;
$base-header-line-height: 1.4 !default;

// Colors (http://sachagreif.com/sass-color-variables/)
$black: #232526 !default;
$white: #fff !default;
$color-cyan-green: #c8e8e0 !default;
$color-classic-matte-black: #1c1c1c !default;
$color-black: $black !default;
$color-white: $white !default;
$color-white-smoke: #f8f8f8 !default;
$color-average: #dc6e10 !default;
$color-good: #dccc10 !default;
$color-gold: #ead9a6 !default;
$color-success-mark: #458745 !default;
$tick: '\2713';
$cross: '\2715';
$color-gray: #7d7d7d !default;
$color-grey: $color-gray !default;
$color-gray-darker: #bcbcbc !default;
$color-dark-gray: #161616 !default;
$color-dark-grey: $color-dark-gray !default;
$color-dark-red: #cc0000 !default;
$color-light-gray: #6d6d6d !default;
$color-light-grey: $color-light-gray !default;
$color-lighter-gray: #e7e7e7 !default;
$color-lighter-grey: $color-lighter-gray !default;
$color-very-light-gray: #f4f4f4 !default;
$color-darker-grey: #e2e1e1 !default;
$color-light-black: #1e2021 !default;
$color-light-sea-green: #d2efea !default;
$color-light-gray-adv: #cccccc !default;
$color-lightest-grey: #fcfcfc !default;
$color-light-grey-breadcrumb: #b0b0b0 !default;
$color-nobel-gray: #a39e9d !default;
$color-black-v2: #000000 !default;
$color-light-gray-v2: #f4f6f4 !default;
$color-gray-v2: #cccccc !default;
$color-dark-gray-v2: #808080 !default;
$color-lighter-gray-v2: #888888 !default;
$color-beige: #cda86e !default;
$color-warm-grey: #777777;
$color-warm-gray: $color-warm-grey;
$color-white-op70: rgba(255, 255, 255, 0.7);
$color-gray-light-alt: #eff0f0;
$color-light-gray2: #e1e1e1;
$color-silver: #c0c0c0;
$color-sharp-black: #111111;
$color-pink-darker: #db008b;
$color-green-lighter: #39a339;

// Brand Colors
$color-pink: #ff4661 !default;
$color-lighter-green: #cde6d9 !default;
$color-light-green: #c8e8e0 !default;
$color-light-green-v2: #95c8c6 !default;
$color-light-green-transparent: rgba(200, 232, 224, 0.75) !default;
$color-light-orange: #fbf8f6 !default;
$color-dark-green: #b6e0d6 !default;
$color-darker-green: #1a7f61 !default;
$color-lighter-sea-green: #a8e3d7 !default;
$color-rose: #deaaac !default;
$color-cool-blue: #a9dce9 !default;
$color-green-v2: #a3d6c7 !default;
$color-error-v2: #ff0000 !default;
$color-filter-grey: #fafafa;
$color-filter-gray: $color-filter-grey;
$color-navy-blue: #0075db;
$color-forest-green: #06891e;

// 2023 font styles
$color-light-grey-2023: #f2f2f2 !default;
$color-grey-2023: $color-gray-v2 !default;
$color-dark-grey-2023: #606060 !default;
$color-green-2023: #a7efd2 !default;

// Font Colors
$base-font-color: $color-black;
$base-accent-color: $color-pink;

// Semi-transparent background
$color-light-green-transparent: rgba(204, 233, 226, 0.75) !default;
$color-light-white-transparent: rgba(249, 251, 250, 0.8) !default;
$color-white-transparent: rgba(255, 255, 255, 0.4) !default;

$color-black-background: rgba(0, 0, 0, 0) !default;
$color-dark-black: #000000;

// Semi-transparent background
$color-light-green-transparent: rgba(204, 233, 226, 0.75) !default;
$color-light-black-transparent: rgba(0, 0, 0, 0.6) !default;

$color-light-black-transparent-four: rgba(0, 0, 0, 0.4) !default;
$color-light-black-transparent-five: rgba(0, 0, 0, 0.5) !default;
$color-dark-black-transparent-seven: rgba(0, 0, 0, 0.75) !default;
$color-dark-black-transparent-eight: rgba(0, 0, 0, 0.8) !default;

$color-green: #a3d6c7 !default;

$color-text: $color-dark-gray !default;
$color-link: $color-dark-gray !default;
$color-link-hover: $color-light-grey !default;

$color-blue: #000217 !default;

$color-focus: #106bfe !default;

// frontend validation variables
$color-valid: #008000;
$color-success: $color-valid;
$color-error: #ff0000;
$color-invalid: $color-error;
$border-color-normal: $color-lighter-gray;
$border-color-error: $color-error;
$icon-background: $color-white;
$inline-error-background: $color-white;
$color-error-msg: #a80000 !default;
$color-success-msg: #087443 !default;
$color-threshold-msg: #b54708 !default;

// If you have questions about why these aren't classes
// (http://www.paulirish.com/2008/conditional-stylesheets-vs-css-hacks-answer-neither/)
// It's because that method triggers compatability mode:
// https://github.com/h5bp/html5-boilerplate/issues/1187
$ie8: "html[data-useragent*='MSIE 8.0']";
$ie9: "html[data-useragent*='MSIE 9.0']";
$ie10: "html[data-useragent*='MSIE 10.0']";
$ie11: "html[data-useragent*='MSIE 11.0']";

// Compass variables
$legacy-support-for-ie6: false;
$legacy-support-for-ie7: false;
$legacy-support-for-ie8: true;
$color-red: #ff0000 !default;

// $font--heading: "Copperplate Gothic Std 32 AB", sans-serif;
// $font--subheading: "ITC Franklin Gothic", sans-serif;
// $font--text: "Adobe Caslon Pro", serif;
// $font--cta: "Typewriter Elite MT Std", serif;
// $font--typewriter: "Typewriter Elite MT Std", serif;

$font--serif: serif !default;
$font--sans: sans-serif !default;
$font--mono: monospace !default;
$font--gotham: 'Gotham' !default;
$font--gotham-medium: 'Gotham Medium' !default;
$font--gotham-bold: 'Gotham Bold' !default;

// Replace me with actual brand fonts

$font--text: $main-font-regular;
$font--heading: $font--text;
$font--subheading: $font--text;

// Max widths for formatters and content containers
$container-max-width-large: 1440px !default;
$container-max-width-xlarge: 1920px !default;

// Content containers outer pad vars
$container-pad-small: 20px !default;
$container-pad-landscape: 48px !default;

// Content Blocks.
$content-block-signup-height: 70px !default;
$content-block-signup-mobile-height: 125px !default;

// Gnav specific vars
$gnav-max-width: 1500px !default;
$gnav-fixed-z-index: 995 !default;
$navbar-height: 81px !default;
$navbar-mobile-height: 55px !default;
$offerbar-height: 66px !default;
$offerbar-mobile-height: 76px !default;
$gnav-height: $navbar-height + $offerbar-height;
$gnav-mobile-height: $navbar-mobile-height + $offerbar-mobile-height;
$gnav-icon--inline: 14px !default;
$gnav-mobile-hpad--outer: 20px !default;
$gnav-mobile-link-height: 34px !default;
$gnav-content-max-height: 385px !default;
$gnav-na-designs: false !default;

// Media queries
// global/_media-queries.scss
$small-range: (0, 640px); // iPhone: $small-up, $small-only
$medium-range: (641px, 768px); // iPad portrait: $medium-up, $medium-only
$large-range: (769px, 1024px); // iPad landscape: $large-up, $large-only
$xlarge-range: (1025px, 1279px); // Desktop interim: $xlarge-up, $xlarge-only
$xxlarge-range: (1280px); // Everything above: $xxlarge-up
$wide-short-range: (2, 1); // Short-wide aspect: $wide-short
$front-tall-range: (760px); // minimum height for front page tall nav
$nav-tall-range: (640px); // minimum height for nav touts
$product-normal-range: (641px, 1279px); // Desktop interim: $product-normal-up, $product-normal-only
$product-wide: (1280px); // Desktop interim: $product-wide-up
$xxxlarge-range: (1367px); //Everything above: $xxlarge-range

// Review Helpfulness
$review_helpful_positive: 'Yes' !default;
$review_helpful_negative: 'No' !default;

// Appointment Booking 2021 vars
$appt-book-color-gray-field: #575757;
$appt-book-color-gray-border: #b7b7b7;
$appt-book-color-gray-disabled: #bababa;
$ab-padding: 30px;
$max-width-xlarge: 1920px;
$xxlarge: 1600px;
$medium-up: 768px !default;
$ab-padding-breakpoints: $medium-up;
$ab-xlarge-padding-breakpoints: ($landscape-up, $max-width-xlarge + $ab-padding);

// ELC BASE - SHARED Design enable
$elc_base_shared_checkout_designs: true !default;
// Waitlist
$waitlist-button: false !default;

// Checkout - dir is sections/checkout/
$pg_checkout_applepay_us: false !default; // _checkout-viewcart.scss
$pg_checkout_cancel_order: false !default; // _checkout-confirmation.scss

// Enable stylings for error message for hazmat product with restricted ingredients.
$enable-restrict-ingredient-error: false !default;
