@import '../../../../scss/theme-bootstrap';

.product-flag {
  &__flag {
    text-transform: uppercase;
    color: $color-black;
    font-size: 13px;
  }
  &__container {
    width: 100%;
    text-align: center;
    @include breakpoint($landscape-up) {
      padding-left: 10px;
      text-transform: uppercase;
      min-height: 25px;
      float: none;
      width: 100%;
    }
    .mpp-container & {
      text-align: left;
      @include breakpoint($landscape-up) {
        text-align: center;
      }
    }
  }
}
